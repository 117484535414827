import React, { useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';

const AuthInitializer = ({ children }) => {
  const [authState, setAuthState] = useState({
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
  });

  useEffect(() => {
    localStorage.setItem('isLoggedIn', authState.isLoggedIn);
  }, [authState.isLoggedIn]);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthInitializer;
