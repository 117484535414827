import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthInitializer from './AuthInitializer';

ReactDOM.render(

    <AuthInitializer>
      <App />
    </AuthInitializer>
,
  document.getElementById('root')
);
