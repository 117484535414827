import React, {  useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Card, Container } from 'react-bootstrap'
//import { AuthContext } from '../AuthContext';
import Sidebar from '../components/Sidebar';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import axios from 'axios';
import { AiFillDelete } from 'react-icons/ai';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../css/Home.css';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

const Home = () => {


  const navigate = useNavigate();

  //const { isLoggedIn } = useContext(AuthContext);
  const token = localStorage.getItem('token_1');
  const config = {
    headers: { 'Authorization': `Bearer ${token}` }
  };
  const BASE_URL = 'http://localhost:3005'


  // useEffect(() => {
  //   if (isLoggedIn) {

  //       fetchCircuitos();
  //       fetchRegistroEscuelas();

  //   }
  // }, [isLoggedIn]);


  const fetchCircuitos = () =>{
    axios.get(BASE_URL + '/circuitos', config)
    .then(data => {
      setCircuitos(data.data.circuito);
    })
    .catch((error) => {
      switch (error.response && error.response.status) {
        case 401:
          console.log("ASD")
          navigate('/login');
          break;
        case 403:
          console.log('Acceso denegado');
          break;
        case 500:
          console.log('Error interno del servidor');
          break;
        default:
          console.error(error);
          break;
      }
    })

  }


  const [selectedCircuito, setSelectedCircuito] = useState('');
  const [circuitos, setCircuitos] = useState([]);
  const [escuelas, setEscuelas] = useState([]);
  const [selectedEscuela, setSelectedEscuela] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [registroEscuelas, setRegistroEscuelas] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null);

  const fetchRegistroEscuelas = () => {
    axios.get(BASE_URL + '/recepcion', config)
    .then(data => {
      setRegistroEscuelas(...registroEscuelas, data.data.log);
    })
    .catch((error) => {
      switch (error.response && error.response.status) {
        case 401:
          navigate('/login');
          break;
        case 403:
          console.log('Acceso denegado');
          break;
        case 500:
          console.log('Error interno del servidor');
          break;
        default:
          console.error(error);
          break;
      }
    })
  }
  useEffect(()=>{
    fetchCircuitos();
    fetchRegistroEscuelas();
  },[])
  const fetchEscuelasByCircuito = (circuitoId) => {
    axios.get(BASE_URL + `/escuelas/${circuitoId}`, config)
      .then(data => {
        setEscuelas(data.data.escuelas);
      })
      .catch((error) => {
        switch (error.response && error.response.status) {
          case 401:
            navigate('/login');
            break;
          case 403:
            console.log('Acceso denegado');
            break;
          case 500:
            console.log('Error interno del servidor');
            break;
          default:
            console.error(error);
            break;
        }
      })
  };
  const handleDeleteRow = () => {
    if (selectedRowToDelete) {
      // Realizar la llamada para eliminar la fila usando el ID del registro seleccionado
      console.log(selectedRowToDelete)
      axios.delete(BASE_URL + `/recepcion/${selectedRowToDelete.EscuelaID}/${selectedRowToDelete.CircuitoID}`, config)
        .then(() => {
          // Actualizar el estado de la tabla excluyendo el registro eliminado
          setRegistroEscuelas(registroEscuelas.filter(registro => registro.EscuelaID !== selectedRowToDelete.EscuelaID));
          setSelectedRowToDelete(null);
          setShowDeleteConfirmation(false);
          toast.success('Eliminación exitosa', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        })
        .catch((error) => {
          switch (error.response && error.response.status) {
            case 401:
              navigate('/login');
              break;
            case 403:
              console.log('Acceso denegado');
              break;
            case 500:
              console.log('Error interno del servidor');
              break;
            default:
              console.error(error);
              break;
          }
        })
    }
  };
  const handleCircuitoChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCircuito(selectedValue);
    setSelectedEscuela(null);
    console.log(selectedValue)
    fetchEscuelasByCircuito(selectedValue);
  };

  const handleEscuelaChange = (e) => {
    const selectedEscuelaId = e.target.value;
    const selectedEscuela = escuelas.find((escuela) => escuela.EscuelaID === parseInt(selectedEscuelaId));
    setSelectedEscuela(selectedEscuela);
  };

  const handleCargarClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = () => {

    const newRegistro = {

      EscuelaID: selectedEscuela.EscuelaID,
      ID: selectedEscuela.EscuelaID,
      CircuitoID: selectedEscuela.CircuitoID,
      Establecimiento: selectedEscuela.Establecimiento,
      NombreResponsable: selectedEscuela.Responsable,
      CantidadMesas: selectedEscuela.CantidadMesas,
      RangoMesas: selectedEscuela.RangoMesas
    };

    axios.post(BASE_URL + `/recepcion/`, newRegistro, config)
      .then(data => {
        const res = data.data.log[0]

        const registro = {
          EscuelaID: res.EscuelaID,
          CircuitoID: res.CircuitoID,
          Establecimiento: res.Establecimiento,
          NombreResponsable: res.Responsable,
          CantidadMesas: res.CantidadMesas,
          RangoMesas: res.RangoMesas,
          FechaFormateada: res.FechaFormateada
        }

        setRegistroEscuelas([ registro,...registroEscuelas]);
        setShowConfirmation(false);
        setSelectedEscuela(null);
        setSelectedCircuito('');
        toast.success('Carga exitosa', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });


      })
      .catch((error) => {
        switch (error.response && error.response.status) {
          case 401:
            navigate('/login');
            break;
          case 403:
            console.log('Acceso denegado');
            break;
          case 500:
            console.log('Error interno del servidor');
            break;
          default:
            console.error(error);
            break;
        }
      })
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const registroColumns = [
    { dataField: 'EscuelaID', text: 'ID' },
    { dataField: 'CircuitoID', text: 'Circuito',  sort: true },
    { dataField: 'Establecimiento', text: 'Escuela', sort: true },
    { dataField: 'NombreResponsable', text: 'Responsable escuela' },
    { dataField: 'CantidadMesas', text: 'Cantidad de mesas' },
    { dataField: 'RangoMesas', text: 'Mesas' },
    { dataField: 'FechaFormateada', text: 'Fecha', sort: true },
    {
      dataField: 'delete',
      text: 'Eliminar',
      formatter: (cell, row) => (
        // Agregar el botón de eliminar a cada fila de la tabla
        <Button variant="outline-danger" size="sm" onClick={() => {
          setSelectedRowToDelete(row);
          setShowDeleteConfirmation(true);
        }}>
          <AiFillDelete />
        </Button>
      )
    }
  ];

  return (
    <>
      <Sidebar />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container>

        <Row>
          <Col md={6}>
            <Card className="flex-fill">
              <Card.Header>Elegí la escuela</Card.Header>
              <Card.Body style={{ width: 1100 }}>
                <label>Selecciona un circuito:</label>
                <div className="select-container" >
                  <select value={selectedCircuito} onChange={handleCircuitoChange} style={{ width: '500px' }}>
                    <option value="">-- Selecciona --</option>
                    {circuitos.map((circuito) => (
                      <option key={circuito.CircuitoID} value={circuito.CircuitoID}>
                        {circuito.Nombre}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedCircuito && (
                  <>
                    <label>Escuelas asociadas:</label>
                    <div className="select-container">

                      <select value={selectedEscuela?.EscuelaID || ''} onChange={handleEscuelaChange} style={{ width: '500px' }}>
                        <option value="">-- Selecciona una opción primero --</option>
                        {escuelas.map((escuela) => (
                          <option key={escuela.EscuelaID} value={escuela.EscuelaID}>
                            {escuela.Establecimiento}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>)}
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            {selectedEscuela && (
              <Card>
                <Card.Header>{selectedEscuela.Establecimiento}</Card.Header>
                <Card.Body>
                  <p>Dirección: {selectedEscuela.Direccion}</p>
                  <p>Responsable: {selectedEscuela.Nombre}</p>
                  <p>Número de responsable: {selectedEscuela.Telefono}</p>

                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>

        <Row>
          {selectedEscuela && (
            <Col md={12}>
              <hr style={{
                color: '#000000',
                backgroundColor: '#000000',
                height: .5,
                borderColor: '#000000'
              }} />
              <div className="button-container">
                <Button variant="success" onClick={handleCargarClick} style={{ width: '200px' }}>
                  Cargar
                </Button>
              </div>
              <hr style={{
                color: '#000000',
                backgroundColor: '#000000',
                height: .5,
                borderColor: '#000000'
              }} />
            </Col>)}
        </Row>

        <Modal show={showConfirmation} onHide={handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> {selectedEscuela?.Establecimiento}</h4>
            <h5>Circuito: {selectedEscuela?.CircuitoID}</h5>
            <hr></hr>
            <p>Dirección: {selectedEscuela?.Direccion}</p>
            <p>Responsable: {selectedEscuela?.Nombre}</p>
            <p>Número de responsable: {selectedEscuela?.Telefono}</p>
            <hr></hr>
            <p>¿Estás seguro de cargar la carpeta de la escuela?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleConfirm}>
              Cargar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Eliminación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <h4> {selectedRowToDelete?.Establecimiento}</h4>
            <h5>Circuito: {selectedRowToDelete?.CircuitoID}</h5>
            <p>¿Estás seguro de eliminar esta carpeta?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleDeleteRow}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>

        <Row>

          <Col md={12}>
            <hr style={{
              color: '#000000',
              backgroundColor: '#000000',
              height: .5,
              borderColor: '#000000'
            }} />

            <BootstrapTable
              bootstrap4
              keyField="EscuelaID"
              data={registroEscuelas}
              columns={registroColumns}
              filter={filterFactory()}
              pagination={ paginationFactory() } 
            />

          </Col>
        </Row>
      </Container>


    </>
  );

};

export default Home;
