import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Card, Container } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import axios from 'axios';
import { AiFillDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../css/Home.css';
import Sidebar from '../components/Sidebar';
import Select from 'react-select'; // Importing React-Select

//const BASE_URL = 'http://localhost:3005';
const BASE_URL = 'https://api.bdatosgeneral.xyz/recepcion'
const Home = () => {



  const navigate = useNavigate();
  const token = localStorage.getItem('token_1');
  const config = {
    headers: { 'Authorization': `Bearer ${token}` }
  };

  const [circuitos, setCircuitos] = useState([]);
  const [escuelas, setEscuelas] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [registroEscuelas, setRegistroEscuelas] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null);

  useEffect(() => {
    fetchCircuitos();
    fetchRegistroEscuelas();
  }, []);

  const fetchCircuitos = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/circuitos`, config);
      setCircuitos(response.data.circuito);
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchRegistroEscuelas = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/recepcion`, config);
      console.log(response.data.log)
      setRegistroEscuelas(response.data.log);

    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchEscuelasByCircuito = async (circuitoId) => {
    try {
      const response = await axios.get(`${BASE_URL}/escuelas/${circuitoId}`, config);
      console.log(response.data.escuelas)
      setEscuelas(response.data.escuelas);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleDeleteRow = async () => {
    if (selectedRowToDelete) {
      try {
        await axios.delete(`${BASE_URL}/recepcion/${selectedRowToDelete.EscuelaID}/${selectedRowToDelete.CircuitoID}`, config);
        setRegistroEscuelas(registroEscuelas.filter(registro => registro.EscuelaID !== selectedRowToDelete.EscuelaID));
        setSelectedRowToDelete(null);
        setShowDeleteConfirmation(false);
        toast.warn('Eliminación exitosa de la carpeta: ' + selectedRowToDelete.Establecimiento, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        handleApiError(error);
      }
    }
  };



  const handleCargarClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    const newRegistro = {
      EscuelaID: selectedEscuelaOption.value.EscuelaID,
      ID: selectedEscuelaOption.value.EscuelaID,
      CircuitoID: selectedEscuelaOption.value.CircuitoID,
      Establecimiento: selectedEscuelaOption.value.Establecimiento,
      NombreResponsable: selectedEscuelaOption.value.Responsable,
      CantidadMesas: selectedEscuelaOption.value.CantidadMesas,
      RangoMesas: selectedEscuelaOption.value.RangoMesas
    };
    console.log(newRegistro)

    const response = await axios.post(`${BASE_URL}/recepcion/`, newRegistro, config)
      .then((response) => {
        const res = response.data.log[0];
        const registro = {
          EscuelaID: res.EscuelaID,
          CircuitoID: res.CircuitoID,
          Establecimiento: res.Establecimiento,
          NombreResponsable: res.NombreResponsable,
          CantidadMesas: res.CantidadMesas,
          RangoMesas: res.RangoMesas,
          FechaFormateada: res.FechaFormateada
        }

        setRegistroEscuelas([registro, ...registroEscuelas]);
        setShowConfirmation(false);
        setSelectedEscuelaOption(null);
        setSelectedCircuitoOption(null);
        toast.success('Escuela ' + registro.Establecimiento + ' cargada correctamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })

      .catch((error) => {

        handleApiError(error);
      })

  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleApiError = (error) => {
    if (error.message === 'Network Error') {
      // Handle the connection refused error here
      toast.error('Sin conexion con el servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error('Connection refused. The server may not be running or is unreachable.');
    } else {


      switch (error.response && error.response.status) {
        case 401:
          navigate('/login');
          break;
        case 403:
          toast.error('Acceso denegado', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        case 500:
          toast.error('Error: ' + error.response.data.mensaje, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        default:
          console.error(error);
          break;
      }
    }
  };

  const registroColumns = [
    { dataField: 'EscuelaID', text: 'ID' },
    { dataField: 'CircuitoID', text: 'Circuito', sort: true },
    { dataField: 'Establecimiento', text: 'Escuela', sort: true },
    { dataField: 'NombreResponsable', text: 'Responsable escuela'},
    { dataField: 'CantidadMesas', text: 'Cantidad de mesas' },
    { dataField: 'RangoMesas', text: 'Mesas' },
    { dataField: 'FechaFormateada', text: 'Fecha', sort: true },
    {
      dataField: 'delete',
      text: 'Eliminar',
      formatter: (cell, row) => (
        <Button variant="outline-danger" size="sm" onClick={() => {
          setSelectedRowToDelete(row);
          setShowDeleteConfirmation(true);
        }}>
          <AiFillDelete />
        </Button>
      )
    }
  ];

  // Define state variables for the selected option
  const [selectedCircuitoOption, setSelectedCircuitoOption] = useState(null);
  const [selectedEscuelaOption, setSelectedEscuelaOption] = useState(null);

  // Create options array for circuitos
  const circuitoOptions = circuitos.map(circuito => ({
    value: {
      CircuitoID: circuito.CircuitoID,
      Nombre: circuito.Nobre,
      PartidoID: circuito.PartidoID
    },
    label: circuito.Nombre
  }));

  // Create options array for escuelas
  const escuelaOptions = escuelas.map(escuela => ({
    value: {
      CantidadMesas: escuela.CantidadMesas,
      CircuitoID: escuela.CircuitoID,
      EscuelaID: escuela.EscuelaID,
      Establecimiento: escuela.Establecimiento,
      Fecha: escuela.Fecha,
      FechaFormateada: escuela.FechaFormateada,
      RangoMesas: escuela.RangoMesas,
      Responsable: escuela.ResponsableID,
      Nombre: escuela.Nombre,
      Apellido: escuela.Apellido,
      Telefono: escuela.Telefono,
      Usuario: escuela.Usuario,
      id: escuela.id,
      Direccion: escuela.Direccion
    },
    label: escuela.Establecimiento + ' - Mesas: '+ escuela.RangoMesas
  }));

  const handleCircuitoChange = (selectedOption) => {
    setSelectedCircuitoOption(selectedOption);
    setSelectedEscuelaOption(null);
    fetchEscuelasByCircuito(selectedOption.value.CircuitoID);
  };

  // Handle change for escuela selection
  const handleEscuelaChange = (selectedOption) => {
    console.log(selectedOption)
    setSelectedEscuelaOption(selectedOption);

  };
  return (
    <>
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />


      <Container>

        <Row>
          <Col md={12} className="w-100">
            <Card className="flex-fill" style={{ width: '100%' }}>
              <Card.Header>Elegí la escuela</Card.Header>
              <Card.Body style={{ width: '100%' }}>
                <Row>
                  <Col className="w-100">
                    <label>Selecciona un circuito:</label>
                    <div className="select-container" >

                      <Select
                        value={selectedCircuitoOption}
                        onChange={handleCircuitoChange}
                        options={circuitoOptions}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%', // Adjust the width here as needed
                            minWidth: 300,
                          })
                        }}
                      />
                    </div>

                  </Col>
                  <Col >
                    {selectedCircuitoOption && (
                      <>
                        <label>Escuelas del circuito <b>{selectedCircuitoOption && selectedCircuitoOption.value.CircuitoID}</b>:</label>
                        <div className="select-container">
                          <Select
                            value={selectedEscuelaOption}
                            onChange={handleEscuelaChange}
                            options={escuelaOptions}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%', // Adjust the width here as needed
                                minWidth: 400,
                              })
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>


        </Row>
        <Row>
          <p></p>
          <Col md={12} >
            {selectedEscuelaOption && (
              <Card>
                <Card.Header>{selectedEscuelaOption.value.Establecimiento}</Card.Header>
                <Card.Body>
                  <p>Dirección: {selectedEscuelaOption.value.Direccion}</p>
                  <p>Responsable: {selectedEscuelaOption.value.Nombre}, {selectedEscuelaOption.value.Apellido}</p>
                  <p>Número de responsable: {selectedEscuelaOption.value.Telefono}</p>

                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>

        <Row>
          {selectedEscuelaOption && (
            <Col md={12}>
              <hr style={{
                color: '#000000',
                backgroundColor: '#000000',
                height: .5,
                borderColor: '#000000'
              }} />
              <div className="button-container">
                <Button variant="success" onClick={handleCargarClick} style={{ width: '200px' }}>
                  Cargar
                </Button>
              </div>
              <hr style={{
                color: '#000000',
                backgroundColor: '#000000',
                height: .5,
                borderColor: '#000000'
              }} />
            </Col>)}
        </Row>

        <Modal show={showConfirmation} onHide={handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> {selectedEscuelaOption?.value.Establecimiento}</h4>
            <h5>Circuito: {selectedEscuelaOption?.value.CircuitoID}</h5>
            <hr></hr>
            <p>Dirección: {selectedEscuelaOption?.value.Direccion}</p>
            <p>Responsable: {selectedEscuelaOption?.value.Nombre}</p>
            <p>Número de responsable: {selectedEscuelaOption?.value.Telefono}</p>
            <hr></hr>
            <p>¿Estás seguro de cargar la carpeta de la escuela?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleConfirm}>
              Cargar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Eliminación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> {selectedRowToDelete?.Establecimiento}</h4>
            <h5>Circuito: {selectedRowToDelete?.CircuitoID}</h5>
            <p>¿Estás seguro de eliminar esta carpeta?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleDeleteRow}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>

        <Row>

          <Col md={12}>
            <hr style={{
              color: '#000000',
              backgroundColor: '#000000',
              height: .5,
              borderColor: '#000000'
            }} />

            <BootstrapTable
              bootstrap4
              keyField="EscuelaID"
              data={registroEscuelas}
              columns={registroColumns}
              filter={filterFactory()}
              pagination={paginationFactory()}
            />

          </Col>
        </Row>
      </Container>

    </>
  );
};

export default Home;
